<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
      style="position: relative"
    >
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`match:save_stage`">
        <el-form-item>
          <el-button type="primary" @click="add">新增赛事阶段</el-button>
        </el-form-item>
      </perContainer>
      <perContainer :perm="`match:publish_rank`">
        <el-form-item>
          <el-button type="primary" @click="publishRank">发布排名</el-button>
        </el-form-item>
      </perContainer>
      <score_manage :type_id="type_id" :match_id="match_id"></score_manage>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage_session`"
            @click="handleSession(scope.row)"
            style="margin-left: 10px"
            >管理场次</perButton
          >
          <perButton
            :perm="`match:stage_group`"
            @click="handleGroup(scope.row)"
            style="margin-left: 10px"
            >管理分组</perButton
          >
          <!-- <perButton
            :perm="`match:stage`"
            @click="handleRank(scope.row)"
            style="margin-left: 10px"
            >查看排名</perButton
          > -->
          <perButton
            :perm="`match:stage_group`"
            @click="handleExport(scope.row)"
            style="margin-left: 10px"
            >导出分组</perButton
          >
          <perButton
            :perm="`match:stage_group`"
            @click="handleExportReplace(scope.row)"
            style="margin-left: 10px"
            >导出替补</perButton
          >
          <perButton
            :perm="`match:stage_group`"
            v-if="type_id === 's2' || type_id === 's3'"
            @click="handlePoints(scope.row)"
            style="margin-left: 10px"
            >押分管理</perButton
          >
          <popconfirmButton
            v-if="scope.row.status == 1"
            :perm="`match:settle_stage`"
            content="是否确认结算？ 结算后将无法修改"
            text="结算"
            @onConfirm="handleSettle(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-if="scope.row.status == 0"
            :perm="`match:publish_stage`"
            content="是否确认发布？"
            text="发布"
            @onConfirm="handlePublish(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-if="scope.row.status == 0"
            :perm="`match:delete_stage`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="'生成新阶段'"
      :visible.sync="stageVisible"
      v-if="stageVisible"
      :close-on-click-modal="false"
      v-loading="loading"
    >
      <el-form ref="stageForm" :model="stageData" label-width="200px">
        <el-form-item
          label="新阶段"
          prop="stage"
          :rules="[
            {
              required: true,
              message: '请选择新阶段',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-select v-model="stageData.stage">
            <el-option :value="''" label="请选择">请选择</el-option>
            <el-option
              v-for="(stage, index) in step"
              :value="index + 1 + ''"
              :label="`第${index + 1}周`"
              :key="`k_stage_${index}`"
            ></el-option>
            <!--            <el-option :value="'1'" label="第1周"></el-option>-->
            <!--            <el-option :value="'2'" label="第2周"></el-option>-->
            <!--            <el-option :value="'3'" label="第3周"></el-option>-->
          </el-select>
        </el-form-item>

        <el-form-item
          label="分组规则"
          prop="group_type"
          :rules="[
            {
              required: true,
              message: '请选择分组规则',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-select v-model="stageData.group_type">
            <el-option :value="''" label="请选择"></el-option>
            <el-option :value="'1'" label="随机分组"></el-option>
            <el-option :value="'2'" label="排序分组"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="比赛开始日期"
          prop="start_time"
          :rules="[
            {
              type: 'date',
              required: true,
              message: '请选择比赛开始日期',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-date-picker
            v-model="stageData.start_time"
            type="date"
            placeholder="选择比赛开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="默认每周比赛日期"
          prop="week_days"
          :rules="[
            {
              type: 'array',
              required: true,
              message: '请选择默认每周比赛日期',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-checkbox-group v-model="stageData.week_days">
            <el-checkbox
              v-for="item in [1, 2, 3, 4, 5, 6, 7]"
              :label="item"
              :key="`weekday_${item}`"
              >{{ `星期${item == 7 ? "日" : item}` }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-if="false"
          label="每日场次"
          prop="session_count"
          :rules="[
            {
              required: true,
              message: '请选择默认每日场次',
              trigger: ['blur', 'change'],
            },
            {
              validator: this.validatorInteger,
              message: '请输入大于0的整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-col :span="6">
            <el-input
              type="number"
              v-model="stageData.session_count"
              maxlength="8"
              placeholder="请输入每日场次"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item
          label="默认场次生成时间"
          prop="session_start"
          :rules="[
            {
              required: true,
              message: '请选择默认场次生成时间',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-col :span="6">
            <el-time-picker
              v-model="stageData.session_start"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59',
              }"
              value-format="HH:mm:ss"
              placeholder="默认场次生成时间"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          label="每场间隔时间（分钟）"
          prop="session_diff"
          :rules="[
            {
              required: true,
              message: '请输入每场间隔时间（分钟）',
              trigger: ['blur', 'change'],
            },
            {
              validator: this.validatorInteger0,
              message: '请输入大于或等于0的整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-col :span="6">
            <el-input
              type="number"
              v-model="stageData.session_diff"
              maxlength="8"
              placeholder="请输入每场间隔时间"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item
          label="每场比赛持续时间（分钟）"
          prop="session_time"
          :rules="[
            {
              required: true,
              message: '请输入每场比赛持续时间（分钟）',
              trigger: ['blur', 'change'],
            },
            {
              validator: this.validatorInteger,
              message: '请输入大于0的整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-col :span="6">
            <el-input
              type="number"
              v-model="stageData.session_time"
              maxlength="8"
              placeholder="请输入每场比赛持续时间"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveStage">确定</el-button>
          <el-button @click="stageVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { MessageBox } from "element-ui";
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
// import { randomStr } from "@/utils/randomStr";
// import importButton from "../../components/importButton";
// import importHistoryButton from "../../components/importHistoryButton";
import score_manage from "./scoreManage";
export default {
  name: "s1_stage",
  components: {
    perButton,
    score_manage,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      ids: [],
      type_id: "",
      match_id: "",
      step: 3,
      loading: false,
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {},
      dataTable: [],
      dataList: [
        {
          prop: "year",
          label: "年份",
          width: 300,
        },
        {
          prop: "type_id",
          label: "赛事",
          width: 300,
          formatter: (row) => {
            return this.match_types[this.type_id];
          },
        },
        {
          prop: "stage",
          label: "赛事阶段",
          width: 200,
          formatter: (row) => {
            return "第" + row.stage + "周";
          },
        },
        {
          prop: "group_type",
          label: "分组规则",
          width: 200,
          formatter: (row) => {
            switch (row.group_type) {
              case 1:
                return "随机分组";
              case 2:
                return "排名分组";
              default:
                return "";
            }
          },
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "编辑中";
              case 1:
                return "线上";
              case 2:
                return "已结算";
              default:
                return "";
            }
          },
        },
      ],
      stageVisible: false,
      stageData: {
        stage: "",
        group_type: "1",
        start_time: "",
        week_days: [],
        session_count: "0",
        session_start: "",
        session_diff: "",
        session_time: "",
      },
      match: false,
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        this.type_id = val.query.type_id;
      },
      immediate: true,
    },
  },
  methods: {
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    validatorInteger0(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于或等于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) >= 0) {
        callback();
      } else {
        callback(new Error("请输入大于或等于0的整数"));
      }
    },
    handleSession(row) {
      this.removeTabByPath(`/match/info/info_s1/session`);
      this.$router.push({
        path: `/match/info/info_s1/session`,
        query: {
          match_id: this.match_id,
          stage: row.stage,
          type_id: this.type_id,
        },
      
        
      });
       
    },
    handleGroup(row) {
      this.removeTabByPath(`/match/info/info_s1/group`);
      this.$router.push({
        path: `/match/info/info_s1/group`,
        query: {
          match_id: this.match_id,
          stage: row.stage,
          type_id: this.type_id,
        },
      });
    },
    handleExport(row) {
      this.$http.download({
        url: "/admin/match/export_stage_group",
        method: "post",
        data: { match_id: this.match_id, stage: row.stage },
      });
    },
    handlePoints(row) {
      this.removeTabByPath(`/match/info/info_s1/pointsManage`);
      this.$router.push({
        path: `/match/info/info_s1/pointsManage`,
        query: {
          match_id: this.match_id,
          stage: row._id,
          type_id: this.type_id,
        },
      });
    },
    handleExportReplace(row) {
      this.$http.download({
        url: "/admin/match/export_stage_replace",
        method: "post",
        data: { match_id: this.match_id, stage: row.stage },
      });
    },

    handleRank(row) {
      this.removeTabByPath(`/match/info/info_s1/rank`);
      this.$router.push({
        path: `/match/info/info_s1/rank`,
        query: {
          match_id: this.match_id,
          stage: row.stage,
          type_id: this.type_id,
        },
      });
    },
    async publishRank() {
      try {
        let confirm = await MessageBox.confirm(
          "发布排名后将更新小程序所有门派排名信息，是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );

        if (confirm != "confirm") {
          return;
        }

        this.loading = true;
        let { data, errorCode } = await this.$http.match.publish_rank({
          match_id: this.match_id,
        });
        this.loading = false;
        if (errorCode != "0000") {
          return;
        }

        this.$message({
          type: "success",
          message: "发布成功",
        });
      } catch (error) {
        console.log(error);
      }
    },
    add() {
      if (this.dataTable.find((r) => r.status != 2)) {
        this.$message({
          type: "error",
          message: "存在未结算的阶段",
        });

        return;
      }

      this.stageData = {
        stage: "",
        group_type: "1",
        start_time: "",
        week_days: (this.match && this.match.session_config.week_day) || [],
        session_count:
          ((this.match && this.match.session_config.match_count + "") || "") +
          "",
        session_start:
          (this.match &&
            new Date(
              "2022/1/1 " + (this.match.session_config.start_time || "")
            )) ||
          "",
        session_diff:
          ((this.match && this.match.session_config.diff) || "") + "",
        session_time:
          ((this.match && this.match.session_config.duration) || "") + "",
      };

      this.stageVisible = true;
    },
    saveStage() {
      this.$refs.stageForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let start_time = this.stageData.session_start;
          try {
            let date = new Date(this.stageData.session_start);
            if (date instanceof Date && !isNaN(date.getTime())) {
              start_time = date.format("hh:mm:ss");
            }
          } catch (e) {
            console.log(e);
          }

          let saveData = {
            match_id: this.match_id,
            stage: Number.parseInt(this.stageData.stage + ""),
            group_type: Number.parseInt(this.stageData.group_type + ""),
            start_time: new Date(this.stageData.start_time),
            week_days: this.stageData.week_days.map((r) =>
              Number.parseInt(r + "")
            ),
            session_count: Number.parseInt(this.stageData.session_count + ""),
            session_start: start_time,
            session_diff: Number.parseInt(this.stageData.session_diff + ""),
            session_time: Number.parseInt(this.stageData.session_time + ""),
          };

          let { data, errorCode } = await this.$http.match.save_stage(saveData);
          this.loading = false;

          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.stageVisible = false;
          this.dataTable.push(data);
        }
      });
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.delete_stage({
        _id: row._id,
        match_id: this.match_id,
      });
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      let index = this.dataTable.indexOf(row);
      if (index >= 0) {
        this.dataTable.splice(index, 1);
      }
    },
    async handlePublish(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.publish_stage({
        _id: row._id,
        match_id: this.match_id,
      });
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "发布成功",
      });

      row.status = data.status;
    },
    async handleSettle(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.match.settle_stage({
        _id: row._id,
        match_id: this.match_id,
      });
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "计算成功",
      });

      row.status = data.status;
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.match.stage_list({
        ...this.searchForm,
        match_id: this.match_id,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    this.type_id = this.$route.query.type_id;
    console.log("32323232--mmmmnhm", this.type_id);
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.match_id = this.$route.query._id;
    this.loading = true;
    let { data, errorCode } = await this.$http.match.detail(this.match_id);
    if (errorCode == "0000" && data) {
      this.match = {
        ...data.match,
        has_stage: data.has_stage,
      };

      this.step = data.match.step;
    }

    this.loading = false;

    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
